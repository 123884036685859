
export const CREATE_OBJECION = 'CREATE_OBJECION'
export const DELETE_OBJECION = 'DELETE_OBJECION'
export const FETCH_ALL_OBJECIONES = 'FETCH_ALL_OBJECIONES'
export const UPDATE_OBJECION = 'UPDATE_OBJECION'

export const CREATE_RESPUESTA = 'CREATE_RESPUESTA'
export const DELETE_RESPUESTA = 'DELETE_RESPUESTA'
export const FETCH_ALL_RESPUESTAS = 'FETCH_ALL_RESPUESTAS'
export const UPDATE_RESPUESTA = 'UPDATE_RESPUESTA'

export const CREATE_SUGERENCIA = 'CREATE_SUGERENCIA'
export const DELETE_SUGERENCIA = 'DELETE_SUGERENCIA'
export const FETCH_ALL_SUGERENCIAS = 'FETCH_ALL_SUGERENCIAS'
export const UPDATE_SUGERENCIA = 'UPDATE_SUGERENCIA'

export const LOGIN = 'LOGIN'
export const VERIFY_USER = 'VERIFY_USER'

export const CREATE_USER = 'CREATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS'
export const UPDATE_USER = 'UPDATE_USER'
